<template>

    <div class="panel">
          <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
              <div class="columns is-mobile">
                  <div class="column is-size-6 has-text-centered">CLIENT CONFIGURATION</div>
              </div>
          </div>
          <hr>
          
        <div class="form">
          <form action="" @submit.prevent="handleSubmit">
  
          <div class="columns">
  
              <div class="column">
                  <b-field label="Register Brand/Model" label-for="clientConfig.storeDevicePOSDTO.registerBrand">
  
                      <b-select 
                          v-model="clientConfig.storeDevicePOSDTO.registerBrand" 
                          placeholder="Select Register Brand/Model" 
                          required>
                          <option value="Verifone Commander" selected>Verifone Commander</option>
                      </b-select>
                  </b-field>
              </div>
          </div>
  
          <div class="columns">
              <div class="column">
                  <b-field label="POS IP Address"  label-for="clientConfig.storeDevicePOSDTO.registerIP">
                      <b-input type="text"
                          v-model="clientConfig.storeDevicePOSDTO.registerIP" 
                          icon="briefcase"
                          maxlength="20"
                          required                   
                          >
                      </b-input>
                  </b-field>
              </div>
          </div>        
  
          <div class="columns">
              <div class="column">
                  <b-field label="User Name" label-for="clientConfig.registerUser">
                      <b-input type="text"
                          v-model="clientConfig.storeDevicePOSDTO.registerUser" 
                          icon="briefcase"
                          maxlength="40"
                          required                   
                          >
                      </b-input>
                  </b-field>
              </div>
          </div>
  
          <div class="columns">
  
              <div class="column">
  
                  <b-field label="Password" label-for="clientConfig.storeDevicePOSDTO.registerPass">
                      <b-input type="password"
                          v-model="clientConfig.storeDevicePOSDTO.registerPass" 
                          icon="briefcase"
                          maxlength="20"
                          required
                          password-reveal                   
                          >
                      </b-input>
                  </b-field>
  
              </div>
          </div>
  
          <div class="columns">
  
          <div class="column">
  
              <b-field label="ATG IP Address" label-for="storeDeviceAtgDTO.atgIP">
                  <b-input type="text"
                      v-model="clientConfig.storeDeviceAtgDTO.atgIP" 
                      icon="briefcase"
                      maxlength="20"
                      required
                      >
                  </b-input>
              </b-field>
  
          </div>
          </div>        
  
          <div>
              <button type="submit" class="button is-primary" :disabled='!formComplete'>Save</button>
          </div> 
          </form>
  <!-- 
          <div>
              {{ atgRegister }}
          </div> 
          <div>
              {{ atgRegisterCurrent }}
          </div>          -->
          
        </div>
  
          <div v-if="isLoading">
              <data-loading 
                  :isLoading="isLoading" 
                  :isFullPage="isFullPage"> 
              </data-loading>
          </div>
  
    </div>
  
      
  </template>
  
  
  <script>
  
  
  import UserStore from "../../store/UserStore"
  import axios from 'axios'
  import router from "../../router"
  import DataLoading from '../../components/app/DataLoading.vue'
  
  export default {
  
      components: {
  
          DataLoading
  
      },
  
      metaInfo: {
          title: 'Update Cash Register Details'
      },
  
      data() {
          return {
              today: new Date(),
              isLoading: false,
              isFullPage: true,
              atgRegisterCurrent: {},
              atgRegister: {},
              clientConfig: {},
              clientConfigCurrent: {},
  
          }
      },
  
      methods: {
          fetchData() {
  
              this.isLoading = true
  
              if (  UserStore.getters.getActiveStore.storeId ) {
                  axios.get('/sapi/stores/' + UserStore.getters.getActiveStore.storeId + "/devices", {
                      headers: {
                          'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                          'Refresh-Token': UserStore.getters.getRefreshToken					},				
                      })
                  .then( response => {
                      if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                      if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
  
                      this.clientConfig = response.data.data
                      this.clientConfigCurrent = Object.assign({}, response.data.data)
  
                  })
                  .catch( (err) => {
                      this.isLoading = false
                      if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
                  })				
              }
              this.isLoading = false            
  
          },
  
          handleSubmit() {
              if (  UserStore.getters.getActiveStore.storeId ) {
                  this.isLoading = true
  
                  if (this.formComplete) {
                      
                    //   const formatYmd = date => date.toISOString().slice(0, 10);
  
                    //   var newExpire = new Date()
  
                    //   var expire = formatYmd(new Date(newExpire.setTime( newExpire.getTime() + 30 * 86400000 )))
  
                      if (this.clientConfig.registerPort == null) {
                          this.clientConfig.registerPort = "443"
                      }
                      axios.post('/sapi/stores/' + UserStore.getters.getActiveStore.storeId + "/devices", {
                            //   "atgIP": this.atgRegister.atgIP,
                            //   "atgPort": this.atgRegister.atgPort,
                            //   "atgBrand": this.atgRegister.atgBrand,
                            //   "registerBrand": this.atgRegister.registerBrand,
                            //   "registerIP": this.atgRegister.registerIP,
                            //   "registerPort": this.atgRegister.registerPort,
                            //   "registerUser": this.atgRegister.registerUser,
                            //   "registerPass": this.atgRegister.registerPass,
                            //   "deviceId": this.atgRegister.deviceId,
                            //   "deviceOsVersion": this.atgRegister.deviceOsVersion,
                            //   "registerMasterUser": this.atgRegister.registerMasterUser,
                            //   "registerMasterPass": this.atgRegister.registerMasterPass,
                            //   "registerOldUser": this.atgRegister.registerOldUser,
                            //   "registerOldPass": this.atgRegister.registerOldPass,
                            //   "active": this.atgRegister.active,
                            //   "registerExpirationDate": expire,
                          },
                          {
                          headers: {
                              'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                              'Refresh-Token': UserStore.getters.getRefreshToken					},				
                          })
                      .then( response => {
                          if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                          if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
  
                          if (response.status === 200) {
                              UserStore.commit('fetchStoreSettings', UserStore.getters.getActiveStore.storeId)
                              this.postSuccess = true
                              this.$buefy.notification.open({
                                      message: 'Store Cash Register Information Updated',
                                      type: 'is-success',
                                      duration: 1000,
                                      'has-icon': true,
                                      'icon-pack': 'fas',
                                      icon: 'exclamation-circle'
                              })
                              router.push({ path: '/' })
                          }
                      })
  
                  }
                  this.isLoading = false
              }
  
          },
      },
  
      computed: {
          formComplete() {
            return true
  
            //   if (
            //       this.atgRegister.registerBrand &&
            //       this.atgRegister.registerIP &&
            //       this.atgRegister.registerUser &&
            //       this.atgRegister.registerPass && 
            //       ( 
            //           this.atgRegisterCurrent.registerBrand != this.atgRegister.registerBrand ||
            //           this.atgRegisterCurrent.registerIP != this.atgRegister.registerIP ||
            //           this.atgRegisterCurrent.registerUser != this.atgRegister.registerUser ||
            //           this.atgRegisterCurrent.registerPass != this.atgRegister.registerPass
            //       )
            //      ) 
            //   {
            //       return true
            //   } else {
            //        return false
            //   }
             
          },
  
          registerPasswordExpiresIn() {
              return UserStore.getters.getStoreRegisterPasswordExpireIn
          },        
  
          registerPasswordExpiresIn2() {
  
  
              // var expireDate = new Date()
  
              if (this.clientConfig.storeDevicePOSDTO.registerExpirationDate != null) {
  
                  var expireDate = new Date(this.clientConfig.registerExpirationDate)
              } else {
                  return null
              }
  
              var timeDiff = expireDate.getTime() - this.today.getTime(); 
              var expireIn = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
              return expireIn
  
          },
  
      },
  
      mounted() {
          
          this.fetchData()
      }
  
  
  }
  </script>
     